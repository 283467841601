import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    countryFlags: false,
    isHeaven: false,
    mounted: false,
    'controller-token-': 'empty_state',
    'requestray-token-': 'empty_state',
    'firewall-token-': 'empty_state',
    'cache-token-': 'empty_state',
    'trans-token-': 'empty_state',
    'wd-token-': 'empty_state',
  },
  getters: {
    getIsHeaven: state => state.isHeaven,
    getCountryFlags: state => state.countryFlags
  },
  mutations: {
    setCountryFlags: (state, value) => state.countryFlags = value,
    setIsHeaven: (state, value) => state.isHeaven = value,
    UPDATE_TOKEN(state, payload) {
      state['controller-token-'] = payload['controller-token-'],
      state['requestray-token-'] = payload['requestray-token-'],
      state['firewall-token-'] = payload['firewall-token-'],
      state['cache-token-'] = payload['cache-token-'],
      state['trans-token-'] = payload['trans-token-'],
      state['wd-token-'] = payload['wd-token-']
    },
    UPDATE_MOUNTED(state, payload) {
      state.mounted = payload
    }
  },
  actions: {
    updateIsHeaven: ({commit}, data) => commit('setIsHeaven', data),
    getFlags: ({commit}, data) => commit('setCountryFlags', data)
  }
})