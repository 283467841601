((g) => {

  g.pixel = {
    base_url: process.env.VUE_APP_API_HOST
  };


  g.pixel.uuidv4 = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  g.pixel.handleIframe = function(
    product_id,
    pixel_id,
    user_id,
    host,
    event,
    event_id,
    affiliate_id,
    amount,
    method,
    sale_id,
    original_amount = 0
  ) {

    let data = {
        column: event === 'view' ? 'view_events_triggered_count' : 'conversion_events_triggered_count',
        seller_id: `${user_id}`,
        pixel_id: `${pixel_id}`,
        product_id: `${product_id}`
    };
    
    fetch(`https://${host}/api/lexip/metrics`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  
    let iframe = document.createElement('iframe');
    iframe.src = `https://${host}/${product_id}?event=${event}&event_id=${event_id}&pixel_id=${pixel_id}${method}&amount=${amount}&a_id=${affiliate_id}&s_id=${sale_id}&original_amount=${original_amount}`;
    iframe.width = 1;
    iframe.height = 1;
    iframe.style.display = 'none';
    document.querySelector('body').appendChild(iframe);
  };


  g.pixel.getPixels = function (event, product_id, event_id, method, affiliate_id, amount, sale_id, chc_id, em, ph) {
    return new Promise((resolve, reject) => {
      fetch(`${g.pixel.base_url}/api/lexip/?product_id=${product_id}&event=${event}&event_id=${event_id}${method}&amount=${amount}&a_id=${affiliate_id}&s_id=${sale_id}&chc_id=${chc_id}&em=${em}&ph=${ph}`)
        .then(res => res.json())
        .then(data => {
          resolve(data.pixels);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        })
    });
  };

  g.pixel.handleEvent = async (event, product_id, affiliate_id, method, amount, original_amount, sale_id = undefined, chc_id = '', em = null, ph = null) => {
    if (!affiliate_id) {
      affiliate_id = '';
    }
    if (!method) {
      method = '';
    } else {
      method = '&method=' + method;
    }
    let event_id = g.pixel.uuidv4();
    let pixels = await g.pixel.getPixels(event, product_id, event_id, method, affiliate_id, amount, sale_id, chc_id, em, ph);
    if (pixels && pixels.length) {
      pixels.forEach(async (pixel) => {
        g.pixel.handleIframe(
          product_id,
          pixel.pixel_id,
          pixel.user_id,
          pixel.host,
          event,
          event_id,
          affiliate_id,
          amount,
          method,
          sale_id,
          original_amount,
        );
      });
    }

  }

})(window);
