export function validateReal(value, { min }) {
  if (!value || value == '') return false;
  const amount = parseFloat(
    value
      .toString()
      .replace('R$ ', '')
      .replace(',', '.')
      .replace('-', '')
  );
  if (amount < min) {
    return false
  }
  return true;
}
