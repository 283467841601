import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../i18n'

Vue.use(VueRouter);
Vue.use(i18n);

const routes = [
  {
    path: '/redirect/:page',
    name: 'Redirect',
    component: () => import('../views/Redirect.vue'),
  },
  {
    path: '/assinatura/:product/',
    name: 'Subscription',
    component: () => import('../views/Subscription.vue'),
  },
  {
    path: '/assinatura/:product/offer/:offer',
    name: 'Subscription',
    component: () => import('../views/Subscription.vue'),
  },
  {
    path: '/:id_product/:lang',
    name: 'ThankYou',
    props: (route) => ({
      ...route.params,
      ...route.query
    }),
    component: () => import('../views/ThankYou.vue'),
  },
  // {
  //   path: '/upsell/:userid',
  //   name: 'Upsell',
  //   component: () => import('../views/UpSell.vue'),
  // },
  // {
  //   path: '/redirect/:page/:affiliate',
  //   name: 'Redirect',
  //   component: () => import('../views/Redirect.vue'),
  // },
  {
    path: '/:product',
    name: 'Index',
    component: () => import('../views/Checkout.vue'),
  },
  {
    path: '/:product/offer/:hash',
    name: 'IndexHash',
    component: () => import('../views/Checkout.vue'),
  },
  {
    name: 'pageNotFound',
    path: '*',
    component: () => import('../views/PageNotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.name === "ThankYou"){
    var lang = to.params.lang;

    let locale = localStorage.getItem('location');

    if(!locale){
      locale = 'BR'
    }

    let path = 'obrigado'
    if (locale === 'BR') {
      path = 'obrigado';
    }else if(locale == "AR" || locale == "CO" || locale == "CL" || locale == "EC" || locale == "GT" || locale == "MX" || locale == "PE" || locale == "UY" || locale == "ES"){
      path ='gracias';
    }else{
      path = 'thanks';
    }

    var fullPath = to.fullPath;
    var linha = fullPath.replace(to.params.lang, path);

    if (path === lang) {
      next();
    }else{
      next(linha)
    }
  }else{
    next();
  }
});

export default router;
