<template>
  <b-container fluid id="app" class="p-0">
    <router-view></router-view>
  </b-container>
</template>

<script>
export default {
  name: "app",
  created() {
    // Remove Preload Index Html
    document.getElementById('preload-index').remove()

    // Validar Domínio Greenn/Heaven
    const url = window.location.href
    if(url.includes(process.env.VUE_CHECKOUT_HEAVEN_PAGE)) {
      this.$store.dispatch('updateIsHeaven', true)
    } else {
      this.$store.dispatch('updateIsHeaven', false)
    }
  }
}
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
@import "./assets/themes.css";

* {
  font-family: "Montserrat", sans-serif;
}

body.preload {
  overflow: hidden;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}

.card-item label {
  font-size: initial !important;
}
.card-item__name,
.card-item__number span {
  font-weight: 600 !important;
}
[data-anima="fade"] {
  animation: showFade 1s forwards;
}
@keyframes showFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
[data-anima="top"] {
  animation: showTop 0.5s forwards;
}
@keyframes showTop {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="bottom"] {
  animation: showBottom 0.5s forwards;
}
@keyframes showBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="right"] {
  animation: showRight 0.5s forwards;
}
@keyframes showRight {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

p {
  font-size: 13px;
  font-weight: normal;
  color: var(--text-color);
  line-height: 1.5;
}

// loading

/* preload */
.carregando {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: var(--bg-checkout);
  z-index: 9999 !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carregando.erro::before {
  content: "Ocorreu um erro ao processar a sua solicitação.";
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  padding: 0 20px;
  background: #ff0c37;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  animation: showTop 0.5s forwards;
}
.carregando.erro.prop::before {
  content: "Ocorreu um erro, não foi possível encontrar a proposta.";
}
.carregando.erro.redirect::before {
  content: "Ocorreu um erro, não foi possível redirecionar para URL da página de vendas";
}
.carregando div {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
// .loading {
//   width: 80px;
// }
// .loading-anima {
//   stroke-dasharray: 425.59;
//   stroke-dashoffset: 425.59;
//   animation: loading-animation 4s forwards infinite;
// }

// .loading-fixed {
//   position: absolute;
//   z-index: -1;
// }
.logo-loading {
  width: 170px;
  margin-top: -180px;
  animation: zoom 1.5s forwards infinite alternate;
}

.carregando.erro .logo-loading {
  animation: none;
}

@keyframes zoom {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

.loading-carregando {
  position: relative;
  left: 0;
  width: 190px;
  border-radius: 10px;
  height: 3px;
  display: block;
  background: var(--border-color);
  margin-top: -30px;
  overflow: hidden;
}
.loading-carregando.heaven {
  margin-top: 12px;
}

.loading-carregando::before {
  content: "";
  width: 40%;
  height: 3px;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  left: 0;
  background: #003e53;
  animation: indeterminate 1.5s forwards infinite;
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

.custom-select {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat !important;
}

.carregando.erro .loading-carregando {
  display: none;
}
.mensagem-erro {
  padding: 40px;
  border: 1px solid #ff0c37;
  background: rgba(255, 12, 55, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  color: #ff0c37;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  display: none;
}
.icon-footer {
  // width: 50px;
  // height: 50px;
}
.icon-footer:first-child {
  width: 90px;
  height: 30px;
}
.item-safe:first-child img {
  width: 25px;
  height: 34.09px;
}
.item-safe:nth-child(2) img {
  width: 28.46px;
  height: 34.12px;
}
.item-safe:nth-child(3) img {
  width: 29.14px;
  height: 31.16px;
}

// notificações
.toast {
  max-width: 400px !important;
  font-size: 14px !important;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: initial !important;
  border: 1px solid;
  box-shadow: none !important;
  border-radius: 5px !important;
  backdrop-filter: blur(0) !important;
  padding: 20px 30px;
  padding-left: 50px;
  font-family: Montserrat, sans-serif;
  outline: none !important;
  animation: showRight 0.3s forwards !important;
}
.b-toast,
.b-toaster-slot {
  max-width: 400px !important;
}
.b-toaster {
  z-index: 9999999 !important;
}
.toast-header,
.toast-body {
  padding: 0 !important;
  position: relative;
  line-height: 1.5 !important;
  font-size: 14px !important;
}
.toast-body {
  color: #5f5c6b !important;
}
.toast .close {
  font-weight: 500 !important;
  line-height: 1;
  color: #5f5c6b;
  text-shadow: none !important;
  opacity: 0.5;
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  outline: none !important;
}

.toast-header strong {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600 !important;
}
.toast-header strong::before {
  content: "";
  background: url("./assets/img/icon/notify_default.svg") no-repeat center
    center;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -30px;
}

// danger
.b-toast-danger .toast-header strong {
  color: #ff0c37;
}
.b-toast-danger .toast-header strong::before {
  background: url("./assets/img/icon/notify_danger.svg") no-repeat center;
}
.b-toast-danger .toast {
  background-color: #fff3f5 !important;
  border-color: #ff0c37 !important;
  color: #ff0c37 !important;
}
.b-toast-danger .toast .toast-header {
  color: #ff0c37 !important;
  background-color: #fff3f5 !important;
  border: none !important;
}
.b-toast-danger.b-toast-solid .toast {
  background-color: #fff3f5 !important;
}

// info
.b-toast-info .toast-header strong {
  color: #3483fa;
}
.b-toast-info .toast-header strong::before {
  background: url("./assets/img/icon/notify_info.svg") no-repeat center;
}
.b-toast-info .toast {
  background-color: #f5f9ff !important;
  border-color: #3483fa !important;
  color: #3483fa !important;
}
.b-toast-info .toast .toast-header {
  color: #3483fa !important;
  background-color: #f5f9ff !important;
  border: none !important;
}
.b-toast-info.b-toast-solid .toast {
  background-color: #f5f9ff !important;
}

// markDown
.description-mark {
  word-wrap: break-word !important;
  // margin: 30px auto !important;
}
.description-mark.preview .github-markdown-body {
  padding: 0;
}
.description-mark h1,
.description-mark h2,
.description-mark h3,
.description-mark h4,
.description-mark h5,
.description-mark h6 {
  padding-bottom: 15px !important;
  border-bottom: 1px solid #dce6f0 !important;
  margin: 40px 0 20px 0;
  font-weight: 600;
}
.description-mark h1 {
  font-size: 2rem;
}
.description-mark h2 {
  font-size: 1.8rem;
}
.description-mark p {
  font-size: 14px;
  color: #81858e;
  line-height: 1.5;
  margin: 20px 0 !important;
  font-weight: normal;
}
.description-mark ul,
.description-mark ol {
  margin: 10px 0;
  // padding: 15px;
}
.description-mark li + li {
  margin-top: 10px;
}
.description-mark blockquote {
  padding: 0 1rem;
  margin-left: 0;
  color: #81858e;
  border-left: 0.3rem solid #ededf0;
}
.description-mark code {
  margin-top: 1rem;
  display: block;
  padding: 1rem;
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 0.9rem;
  color: #567482;
  background-color: #f3f6fa;
  border-radius: 0.3rem;
}
.description-mark table {
  border-collapse: collapse;
  border-spacing: 0;
  display: block;
  width: 100%;
  overflow: auto;
  word-break: normal;
  word-break: keep-all;
  -webkit-overflow-scrolling: touch;
}
.description-mark table th,
.description-mark table td {
  padding: 0.5rem 1rem;
  border: 1px solid #ededf0;
}

// preview
.description-mark.preview h1,
.description-mark.preview h2 {
  font-size: 1rem !important;
}

.description-mark.preview * {
  font-size: 13px !important;
}

// toolltip
.tooltip-inner {
  max-width: 100%;
  color: #fff !important;
  font-weight: normal !important;
  padding: 10px;
  font-size: 13px !important;
}

// .grecaptcha-badge {
//     top: 66px !important;
//     left: 45vw !important;
//     position: absolute !important;
// }

.tab-active-dark,
.tab-active-dark:hover {
  background: transparent;
  border: 1px solid white !important;
  color: white !important;

  p {
    color: white !important;
  }

  .icon {
    color: white !important;
  }
}
</style>
