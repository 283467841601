import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/assets/scss/_multiple-select.scss'
import {
  library
} from '@fortawesome/fontawesome-svg-core';
import {
  fas
} from '@fortawesome/free-solid-svg-icons';
import {
  fab
} from '@fortawesome/free-brands-svg-icons';
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome';
import VeeValidate from 'vee-validate';
import {
  Validator
} from 'vee-validate';
import pt_BR from 'vee-validate/dist/locale/pt_BR';
import router from './router';
import store from './store'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import VueTheMask from 'vue-the-mask'
import validate from './validators/cpf_cnpj';
import { validateReal } from './validators/rules';

import UUID from "vue-uuid";
import i18n from './i18n'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

// Preview MarkDown
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import hljs_preview from 'highlight.js';

// estilos markdown
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

// pixel novo
import pixel from './assets/js/pixel/pixel.js'
Vue.use(pixel)

export const EventBus = new Vue();

VMdPreview.use(githubTheme, {
  Hljs: hljs_preview,
});
Vue.use(VMdPreview);

Vue.use(VueTheMask)
library.add(fas);
library.add(fab);
Vue.use(VeeValidate)
Validator.localize('pt_BR', pt_BR);
Validator.extend('cpf_cnpj', validate);
Validator.extend('min_value', validateReal, {
  paramNames: ['min']
});

import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha/src/index.js'
Vue.component('vue-programmatic-invisible-google-recaptcha', VueProgrammaticInvisibleGoogleRecaptcha)

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(UUID);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
